<template>
  <div class="building" v-loading="loading">
    <div class="flex_between bbe9s">
      <div class="title f-left p10">任务信息</div>
      <div class="flex_start">
        <div class="flex_start mr20">
          <el-button type="info" style="height: 40px" @click="godetail"
            >查看订单详情</el-button
          >
          <el-button
            v-if="wiahulema2"
            type="success"
            @click="waihu('dian')"
            style="height: 40px"
            >外呼{{ waihudian.username }}店长</el-button
          >
          <el-button v-else-if="!wiahulema2" type="danger" style="height: 40px"
            >呼叫中</el-button
          >
          <el-button
            v-if="wiahulema"
            type="warning"
            @click="waihu"
            style="height: 40px"
            >外呼客户</el-button
          >
          <el-button v-else-if="!wiahulema" type="danger" style="height: 40px"
            >呼叫中</el-button
          >
        </div>
        <div style="width: 53px">
          <span
            class="f13 mt30 Shrink"
            @click="Shrink(0)"
            style="color: #aba9a9"
            >{{ isindex[0].msg }}</span
          >
        </div>
      </div>
    </div>
    <!-- 信息内容 -->
    <div
      class="flex_column f14 bbe9s pb20 mt10"
      :style="{ display: isindex[0].tt == 't1' ? 'none' : 'flex' }"
    >
      <div class="p5">
        <el-row type="flex" class="row-bg" justify="space-between">
          <el-col :span="8">
            <div class="flex_start mr20 flex_items mt10">
              <span class="mr10 t1sp">客户姓名:</span>
              <span>{{ infos2.contacts }}</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex_start mr20 flex_items mt10">
              <span class="mr10 t1sp">联系方式:</span>
              <span>{{ infos2.telephone }}</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex_start mr20 flex_items mt10">
              <span class="mr10 t1sp">房屋问题:</span>
              <span v-if="infos2.problemTitle">{{ infos2.problemTitle }}</span>
              <span v-if="infos2.problemTitle1"
                >、{{ infos2.problemTitle1 }}</span
              >
              <span v-if="infos2.problemTitle2"
                >、{{ infos2.problemTitle }}</span
              >
            </div>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="space-between">
          <el-col :span="8">
            <div class="flex_start mr20 flex_items mt10">
              <span class="mr10 t1sp">订单来源:</span>
              <span v-if="infos2.channelTitle != null"
                >{{ infos2.channel }}-{{ infos2.channelTitle }}</span
              >
              <span v-else>{{ infos2.channel }}</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex_start mr20 flex_items mt10">
              <span class="mr10 t1sp">下单时间:</span>
              <span>{{ infos2.created_time }}</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex_start flex_items mt10">
              <span class="mr10 t1sp">家庭地址:</span>
              <div>{{ infos2.addres }}</div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="flex_start flex_items p5">
        <span class="mr10 t1sp">添加微信:</span>
        <div style="width: 574px">
          <span v-if="infos2.wechat == 0">否</span>
          <span v-else>是</span>
        </div>
      </div>
      <div class="flex_start flex_items p5">
        <span class="mr10 t1sp">备注信息:</span>
        <div style="width: 574px">{{ infos2.remarks }}</div>
      </div>
    </div>
    <div>
      <span class="f13 mt10 Shrink" @click="Shrink(1)" style="color: #aba9a9">{{
        isindex[1].msg
      }}</span>
    </div>
    <!-- 操作记录 -->
    <div class="flex_between pb10 mt10 bbe9s">
      <div class="title f-left p10">操作记录</div>
    </div>
    <!-- 内容 -->
    <div
      class="flex_column f14 bbe9s pb30"
      :style="{ display: isindex[1].tt == 't1' ? 'none' : 'flex' }"
    >
      <div class="p5">
        <el-row type="flex" class="row-bg" justify="space-between">
          <el-col :span="8">
            <div class="flex_start mr20 flex_items mt10">
              <span class="mr10 t1sp">接单店长:</span>
              <span>{{ infos2.username }}</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex_start mr20 flex_items mt10">
              <span class="mr10 t1sp">订单状态:</span>
              <span>{{ infos2.states }}</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex_start mr20 flex_items mt10">
              <span class="mr10 t1sp">开工时间:</span>
              <span>{{ infos2.sta_time }}</span>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="space-between">
          <el-col :span="8">
            <div class="flex_start mr20 flex_items mt10">
              <span class="mr10 t1sp" style="width: 100px">预计完工时间:</span>
              <span>{{ infos2.up_time }}</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex_start mr20 flex_items mt10">
              <span class="mr10 t1sp">签约金额:</span>
              <span>{{ infos2.totalprice }}元</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex_start mr20 flex_items mt10">
              <span class="mr10 t1sp" style="width: 84px">已支付金额:</span>
              <span>{{ total }}元</span>
            </div>
          </el-col>
        </el-row>
        <div class="flex_start mr20 flex_items mt10">
          <span class="mr10 t1sp" style="width: 84px">待支付金额:</span>
          <span>{{ waiting }}元</span>
        </div>
      </div>
      <!-- 施工记录 -->
      <div class="flex_column pb10 mt10">
        <div class="title f-left p10 bbe9s">施工记录</div>
        <div class="mt20 innerkuang">
          <el-table :data="jindus" border>
            <el-table-column
              prop="store_name"
              label="所属店铺"
            ></el-table-column>
            <el-table-column
              prop="created_at"
              label="施工时间"
            ></el-table-column>
            <el-table-column prop="username" label="师傅姓名"></el-table-column>
            <el-table-column label="施工内容">
              <template slot-scope="scope">
                <span>{{ scope.row.title }}:{{ scope.row.describe }}</span>
              </template>
            </el-table-column>
            <el-table-column label="施工图片">
              <template slot-scope="scope">
                <el-button
                  v-if="scope.row.images.length > 0"
                  :data-img="scope.row.images"
                  type="primary"
                  size="small"
                  class="m-t-5 mr5"
                  @click="$imgPreview"
                  >查看图片</el-button
                >
                <el-button
                  v-if="scope.row.videos.length > 0"
                  :data-vid="scope.row.videos"
                  type="primary"
                  size="small"
                  class="mt5"
                  style="margin-left: 0"
                  @click="$imgPreview"
                  >查看视频</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <!-- 客服跟进记录 -->
      <div class="flex_column pb20 mt20 f16">
        <div class="title f-left p10 bbe9s">客服跟进记录</div>
        <div class="mt20 innerkuang">
          <el-table :data="infos2.through" border>
            <el-table-column prop="th_time" label="时间"></el-table-column>
            <el-table-column prop="mode" label="方式"></el-table-column>
            <el-table-column label="处理人">
              <template slot-scope="scope">
                <div>
                  <span v-if="scope.row.role == 1"
                    >{{ scope.row.username }}/客服</span
                  >
                  <span v-else>{{ scope.row.username }}/店长</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="是否接通">
              <template slot-scope="scope">
                <span v-if="scope.row.conversation == 0">是</span>
                <span v-if="scope.row.conversation == 2">否</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="end_time"
              label="下次跟进时间"
            ></el-table-column>
            <el-table-column prop="remar" label="沟通内容"></el-table-column>
            <el-table-column label="沟通录音">
              <template slot-scope="scope">
                <div v-if="scope.row.record.length == 0">暂无录音</div>
                <div v-else>
                  <audio
                    style="width: 100%; outline: none"
                    :src="scope.row.record[0].monitorfilename"
                    controls="controls"
                  ></audio>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div>
      <span class="f13 mt10 Shrink" @click="Shrink(2)" style="color: #aba9a9">{{
        isindex[2].msg
      }}</span>
    </div>
    <!-- 任务操作-签约跟进 -->
    <div class="flex_between pb10 mt10 bbe9s">
      <div class="title f-left p10">任务操作-施工中跟进</div>
    </div>
    <!-- 内容 -->
    <div
      class="flex_warp flex_items bbe9s f16 pb20"
      :style="{ display: isindex[2].tt == 't1' ? 'none' : 'flex' }"
    >
      <div class="flex_warp flex_items m-r-60">
        <div class="flex_start flex_items mt20 mr20">
          <span class="mr20 isok">下次跟进时间</span>
          <el-date-picker
            :clearable="true"
            v-model="isok.time1"
            type="datetime"
            placeholder="选择下次跟进时间"
          ></el-date-picker>
        </div>
        <div class="m-r-80 flex_start flex_items mt20">
          <span class="mr20 isok">客户电话是否接通</span>
          <el-radio-group v-model="isok.istong" class="flex_items f14">
            <el-radio :label="0">是</el-radio>
            <el-radio :label="2">否</el-radio>
          </el-radio-group>
        </div>
        <div class="m-r-80 flex_start flex_items mt20">
          <span class="mr20 isok">施工师傅是否准时</span>
          <el-radio-group v-model="isok.zhunshi" class="flex_items f14">
            <el-radio :label="1" @click.native.prevent="clickitem('zhunshi', 1)"
              >是</el-radio
            >
            <el-radio :label="2" @click.native.prevent="clickitem('zhunshi', 2)"
              >否</el-radio
            >
          </el-radio-group>
        </div>
        <div class="m-r-80 flex_start flex_items mt20">
          <span class="mr20 isok">施工现场是否整洁</span>
          <el-radio-group v-model="isok.xianchang" class="flex_items f14">
            <el-radio
              :label="1"
              @click.native.prevent="clickitem('xianchang', 1)"
              >是</el-radio
            >
            <el-radio
              :label="2"
              @click.native.prevent="clickitem('xianchang', 2)"
              >否</el-radio
            >
          </el-radio-group>
        </div>
      </div>
      <!-- 现场最不满意点 -->
      <div class="flex_start flex_items m-r-60 mt20">
        <span class="mr20 isok">现场最不满意点</span>
        <el-select
          v-model="nomanyi"
          placeholder="现场最不满意点"
          :clearable="true"
        >
          <el-option
            v-for="item in nomanyis"
            :key="item"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>
      </div>
      <!-- 现场最满意点 -->
      <div class="flex_start flex_items m-r-60 mt20">
        <span class="mr20 isok">现场最满意点</span>
        <el-select v-model="manyi" placeholder="现场最满意点" :clearable="true">
          <el-option
            v-for="item in manyis"
            :key="item"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>
      </div>
      <div class="flex_start flex_items m-r-60 mt20">
        <span class="mr20 isok">话术推荐</span>
        <div style="width: 75%">{{ infos2.Colloquialism }}</div>
      </div>
      <!-- 备注信息 -->
      <div class="flex_start flex_items m-r-60 mt20">
        <span class="mr20" style="width: 173px">沟通备注</span>
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入沟通备注信息"
          v-model="isok.textarea"
        ></el-input>
      </div>
      <!-- 是否投诉 -->
      <div class="flex_start flex_items mt20">
        <span class="mr20 isok">是否发起投诉</span>
        <el-radio-group v-model="isok.tousu" class="flex_items f14">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="2">否</el-radio>
        </el-radio-group>
      </div>
    </div>
    <!-- 投诉详情 -->
    <div v-if="isok.tousu == 1" class="flex_between pb10 mt20">
      <div class="title f-left p10">投诉详情</div>
    </div>
    <div
      v-if="isok.tousu == 1"
      class="flex_warp f16 mt10 bbe9s pb20"
      :style="{ display: isindex[3].tt == 't1' ? 'none' : 'flex' }"
    >
      <div class="flex_start mr20 flex_items mt10">
        <span class="mr10 t1sp">投诉时间</span>
        <el-date-picker
          :clearable="false"
          v-model="isok.tousutime"
          type="datetime"
          placeholder="选择投诉时间"
        ></el-date-picker>
      </div>
      <div class="flex_start flex_items mt10 m-r-60">
        <span class="mr20 t1sp" style="width: 67px">投诉原因</span>
        <el-select
          v-model="isok.tousureason"
          multiple
          collapse-tags
          placeholder="投诉原因"
          style="width: 330px"
        >
          <el-option
            v-for="item in tousureasons"
            :key="item"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>
      </div>
      <div class="m-r-60 mt10 flex_warp flex_items">
        <div>
          <span class="mr20 t1sp" style="width: 67px">投诉店长</span>
          <el-select
            class="mr10"
            filterable
            v-model="isok.tousudian"
            collapse-tags
            placeholder="选择投诉店长"
          >
            <el-option
              v-for="item in shoppers"
              :key="item.user_id"
              :label="item.usernamestorename"
              :value="item.user_id"
            ></el-option>
          </el-select>
        </div>
        <div>
          <span class="mr20 t1sp" style="width: 67px">投诉师傅</span>
          <el-select
            multiple
            collapse-tags
            v-model="isok.tousushi"
            filterable
            placeholder="投诉师傅"
          >
            <el-option
              v-for="item in shifus"
              :key="item.id"
              :label="item.usernamestorename"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
      </div>
      <!-- 客户要求 -->
      <div class="flex_start flex_items m-r-60 mt20">
        <span class="mr20 t1sp">客户要求</span>
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入客户要求"
          v-model="isok.kehuyaoqiu"
        ></el-input>
      </div>
      <!-- 问题图片 -->
      <div class="flex_start flex_items m-r-60 mt20">
        <span class="mr20 t1sp" style="width: 80px">问题图片</span>
        <el-upload
          drag
          action="https://public.yiniao.co/api/v1/upload"
          list-type="picture-card"
          :multiple="mutrue"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove"
          :on-success="upsuccess"
          :data="tuzhi"
        >
          <i class="el-icon-plus"></i>
        </el-upload>

        <el-dialog :visible.sync="tudiag" :modal="false">
          <img width="100%" :src="dialogImageUrl" alt />
        </el-dialog>
      </div>
    </div>
    <div v-if="isok.tousu == 1">
      <span class="f13 mt10 Shrink" @click="Shrink(3)" style="color: #aba9a9">{{
        isindex[3].msg
      }}</span>
    </div>
    <!-- 提交 -->
    <div>
      <el-button class="tijiao" type="primary" @click="tijiao"
        >提交处理结果</el-button
      >
    </div>
  </div>
</template>
<script>
export default {
  props: ["currentPage3"],
  data() {
    return {
      loading: true,
      waiting: "",
      isindex: [
        {
          tt: "-1",
          msg: "--收起--",
        },
        {
          tt: "t1",
          msg: "--展开--",
        },
        {
          tt: "t1",
          msg: "--展开--",
        },
        {
          tt: "-1",
          msg: "--收起--",
        },
      ],
      infos2: {},
      jindus: [],
      isok: {
        istong: 0,
        zhunshi: 1,
        xianchang: 1,
        time1: "",
        textarea: "",
        tousu: 2,
        tousureason: [],
        tousutime: "",
        tousudian: [],
        tousushi: [],
        kehuyaoqiu: "",
      },
      shoppers: [],
      shifus: [],
      tousureasons: [
        "偷工减料质量差",
        "不专业，方案给错误",
        "工期拖延、安排不紧凑",
        "服务态度差，沟通没耐心",
        "施工人员不准时不守时，不告知安排",
        "家里家具财产等遭受损失",
        "承诺未兑现",
        "引导走私单",
        "恶意增项",
      ],
      nomanyis: [
        "第二天安排不告知",
        "施工人员不守时",
        "施工现场太脏了",
        "其它",
        "没有",
      ],
      nomanyi: "",
      manyis: ["师傅干活仔细", "施工守时", "现场干净整洁", "其它", "没有"],
      manyi: "",
      // 图片上传
      mutrue: true,
      dialogImageUrl: "",
      tudiag: false,
      tuzhi: { app_name: "public" },
      imgs: [],
      // 外呼
      wiahulema: true,
      wiahulema2: true,
      jige: "",
      wiahulema: true,
      wiahulema2: true,
      waihulun: 0,
      waihudian: { username: "", mobile: "" },
      isSaveState: true,
      ExternalData: "",
      call: [],
      total: 0,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.util
        .get(this.HOST + "/Order/info", {
          order_id: this.$store.state.order_id,
          Colloquialism: 3,
          // order_id: "22"
        })
        .then((res) => {
          if (res.code == 200) {
            this.infos2 = res.data;
            let total = 0;
            for (let i = 0; i < res.data.payment.length; i++) {
              total += Number(res.data.payment[i].total);
            }
            // 待支付jine保留两位小数
            let tempVal = parseFloat(res.data.totalprice - total).toFixed(3);
            this.total = total;
            this.waiting = tempVal.substring(0, tempVal.length - 1);
            this.isok.tousutime = this.util.intertime(this.util.getnow());
            this.jindu();
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 获取施工进度
    jindu() {
      this.util
        .get(this.HOST + "/Mast/List", {
          order_id: this.$store.state.order_id,
          // order_id: "5087"
        })
        .then((res) => {
          if (res.code == 200) {
            this.jindus = res.data;
            for (let i = 0; i < this.jindus.length; i++) {
              this.jindus[i].images = [];
              this.jindus[i].videos = [];
              this.jindus[i].created_at = this.util.timestampToTime(
                this.jindus[i].created_at
              );
              if (this.jindus[i].resource.length > 0) {
                for (let j = 0; j < this.jindus[i].resource.length; j++) {
                  if (
                    this.jindus[i].resource[j].mime_type.split("/")[0] ==
                    "image"
                  ) {
                    this.jindus[i].images.push(
                      this.jindus[i].resource[j].domain +
                        this.jindus[i].resource[j].path
                    );
                  } else {
                    this.jindus[i].videos.push(
                      this.jindus[i].resource[j].domain +
                        this.jindus[i].resource[j].path
                    );
                  }
                }
              }
            }
            this.shop();
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 获取店长
    shop() {
      this.util
        .get(this.HOST + "/Common/user", {
          order_id: this.$store.state.order_id,
          city_id: 0,
        })
        .then((res) => {
          if (res.code == 200) {
            this.shoppers = res.data;
            this.teacher();
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 获取师傅
    teacher() {
      this.util
        .get(this.HOST + "/Common/getMaster", {
          order_id: this.$store.state.order_id,
          // order_id: "5082"
        })
        .then((res) => {
          if (res.code == 200) {
            this.shifus = res.data;
            // this.loading = false;
            this.dianzhangphone();
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 获取店长号码名字
    dianzhangphone() {
      this.util
        .get(this.HOST + "/User/findShopOwner", {
          order_id: this.$store.state.order_id,
        })
        .then((res) => {
          if (res.code == 200) {
            this.waihudian = res.data;
            this.loading = false;
          } else {
            this.loading = false;
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 跳转详情
    godetail() {
      this.util.godetail(this.infos2);
    },
    // 发起外呼
    waihu(aa) {
      if (!this.wiahulema || !this.wiahulema2) {
        this.$message({
          showClose: true,
          message: "不能同时呼叫!",
          type: "warning",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      let phone = "";
      if (aa == "dian") {
        if (this.waihudian.username == "") {
          this.$message({
            showClose: true,
            message: "该订单暂未指派店长!",
            type: "warning",
            duration: 1200,
            offset: 90,
          });
          return false;
        } else {
          phone = this.waihudian.mobile;
        }
      } else {
        phone = this.waihudian.telephone;
      }
      // 打包后
      this.$jsonp(this.HOST4, {
        account: "N000000015324",
        appid: "ncvsorfw58bk2o6r",
        secret: "31792f00-d2e5-11e9-8be9-b3731625d6b2",
        output: "jsonp",
      }).then((res) => {
        if (res.success) {
          if (aa == "dian") {
            this.wiahulema2 = false;
          } else {
            this.wiahulema = false;
          }
          this.kaihu(res.accessToken, phone, aa);
        }
      });
    },
    kaihu(token, phone, aa) {
      let timestamp = new Date().getTime();
      let good = "customer";
      if (aa == "dian") {
        good = "shopowner";
      }
      let kk = timestamp + "-" + good;
      this.lunxun(kk);
      // 打包后
      this.$jsonp(this.HOST5, {
        phonenum: phone,
        integratedid: sessionStorage.getItem("integratedid"),
        accessToken: token,
        account: "N000000015324",
        flag: 106,
        ExternalData: "main:" + kk,
        output: "jsonp",
      }).then((res) => {
        if (res.success) {
          if (aa == "dian") {
            this.wiahulema2 = false;
          } else {
            this.wiahulema = false;
          }
        }
      });
    },
    lunxun(kk) {
      this.waihulun = 1;
      let that = this;
      let arr = [];
      that.iCount = window.setInterval(function () {
        that.$http
          .get(that.HOST + "/Callback/jie", {
            params: {
              ExternalData: kk,
            },
          })
          .then((res) => {
            //console.log(res);
            if (res.data.code == 200) {
              that.waihulun = 0;
              that.wiahulema = true;
              that.wiahulema2 = true;
              that.isSaveState = true;
              that.call.push(res.data.data.id);
              clearInterval(that.iCount);
            } else {
              arr.push(res.data.code);
              if (arr.length == 35) {
                that.waihulun = 0;
                that.wiahulema = true;
                that.wiahulema2 = true;
                that.isSaveState = true;
                arr = [];
                clearInterval(that.iCount);
              }
            }
          })
          .catch(function (error) {
            // 请求失败处理
            window.console.log(error);
          });
      }, 2000);
    },
    // 图片上传
    uploadFile(file) {
      this.formDate.append(file, file.file);
    },
    handleRemove(file, fileList) {
      //console.log(file, fileList);
      for (var i = 0; i < this.imgs.length; i++) {
        if (this.imgs[i] == file.response.data.file.path) {
          this.imgs.splice(i);
        }
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.tudiag = true;
    },
    upsuccess(response, file, fileList) {
      //console.log(response);
      this.imgs.push(response.data.file.path);
    },
    // 单选点击取消
    clickitem(kind, e) {
      if (kind == "zhunshi") {
        this.isok.zhunshi = this.util.cancleradio(kind, e, this.isok.zhunshi);
      } else if (kind == "xianchang") {
        this.isok.xianchang = this.util.cancleradio(
          kind,
          e,
          this.isok.xianchang
        );
      } else {
      }
    },
    // 收缩
    Shrink(num) {
      if (this.isindex[num].tt == "t1") {
        this.isindex[num].tt = "-1";
        this.isindex[num].msg = "--收起--";
      } else {
        this.isindex[num].tt = "t1";
        this.isindex[num].msg = "--展开--";
      }
    },
    tijiao() {
      let directionvarchar = this.isok.understand;
      let tousutime = this.isok.tousutime;
      let ttme = this.util.datatime(this.isok.time1);
      if (this.infos2.requirements != null && this.isok.tousu == 1) {
        this.$message({
          showClose: true,
          type: "error",
          message: "此订单已生成投诉，不可重复投诉!",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      if (directionvarchar == "其它方面") {
        directionvarchar = this.isok.other2;
        if (directionvarchar == "") {
          this.$message({
            showClose: true,
            type: "error",
            message: "请输入客户需要了解方面!",
            duration: 1200,
            offset: 90,
          });
          return false;
        }
      }
      let order_id = this.$store.state.order_id;
      if (ttme == "NaN-NaN-NaN NaN:NaN:NaN") {
        ttme = "";
        // this.$message({
        //   type: "error",
        //   message: "请输入下次跟进时间"
        // });
        // return false;
      }
      if (
        this.util.datatime(tousutime) == "NaN-NaN-NaN NaN:NaN:NaN" &&
        this.isok.tousu == 1
      ) {
        this.$message({
          showClose: true,
          type: "error",
          message: "请输入投诉时间",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      if (this.isok.tousu == 1 && this.isok.tousureason.length == 0) {
        this.$message({
          showClose: true,
          type: "error",
          message: "请选择投诉原因",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      if (
        this.isok.tousu == 1 &&
        this.isok.tousudian.length == 0 &&
        this.isok.tousushi.length == 0
      ) {
        this.$message({
          showClose: true,
          type: "error",
          message: "请选择投诉店长或者师傅!",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      this.$loading({
        fullscreen: true,
        background: "rgba(0, 0, 0, 0.7)",
        text: "任务处理中...",
      });
      this.util
        .post(this.HOST + "/Order/ProcessingTasks", {
          order_id: order_id,
          through_id: 0,
          type: 4,
          connect: this.isok.istong,
          remar: this.isok.textarea,
          end_time: this.util.datatime(this.isok.time1),
          cc_id: this.$store.state.cc_id,
          on_time: this.isok.zhunshi,
          clean: this.isok.xianchang,
          dissatisfied: this.nomanyi,
          satisfied: this.manyi,
          complaint: this.isok.tousu,
          user_id: "",
          Call_record: this.call,
        })
        .then((res) => {
          if (res.code == 200) {
            if (this.isok.tousu == 2) {
              this.$loading().close();
              this.$message({
                showClose: true,
                message: "任务处理成功!",
                type: "success",
                duration: 1200,
                offset: 90,
              });
              setTimeout(() => {
                this.$store.commit("setMessage", false);
                this.$store.commit("settask", 1);
              }, 1200);
            } else {
              this.taketousu(order_id);
            }
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 生成投诉
    taketousu(order_id) {
      let tousutime = this.isok.tousutime;
      this.util
        .post(this.HOST + "/Order/Tou", {
          reason: this.isok.tousureason.toString(","),
          shopowner: this.isok.tousudian.toString(","),
          masterworker: this.isok.tousushi.toString(","),
          picture: JSON.stringify(this.imgs)
            .replace(/\"/g, "")
            .replace(/\[|]/g, ""),
          requirement: this.isok.kehuyaoqiu,
          create_time: this.util.datatime(tousutime),
          order_id: order_id,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: "任务处理成功!",
              type: "success",
              duration: 1200,
              offset: 90,
            });
            setTimeout(() => {
              this.$store.commit("setMessage", false);
              this.$store.commit("settask", 1);
              this.$emit("getDataAgain", this.currentPage3);
              this.$emit("update:currentPage3", this.currentPage3);
            }, 1200);
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
  },
};
</script>
<style>
.t1sp {
  width: 77px;
  color: #3f51b5;
}
.Shrink {
  cursor: pointer;
  float: right;
  display: inline-block;
}
.huashu {
  white-space: normal;
  word-break: break-all;
  overflow: hidden;
}
.tijiao {
  margin: 50px auto 0 auto;
  display: flex;
}
</style>
